import { FC, useContext } from 'react';
import { AccessibilityMapItemContext } from '../../../../../context/CalendarItemContext';
import classNames from 'classnames';
import { AccessibilityMapContent } from '../components/LineContent/variants/accessibilityMap/AccessibilityMapContent';
import { TimeRangeContext } from '../../../../../context/TimeRangeContext';
import { CalendarColors } from '../../../../../utils/calendarColors';
import { TimetableTypeCodeEnum } from '../../../../../../../../../../server';
import {CalendarDisplayType} from "../../../../../../Calendar/utils/data/calendarConstants";

interface AccessibilityMapCalendarItemProps {
    displayType:CalendarDisplayType;
}

export const AccessibilityMapCalendarItem: FC<AccessibilityMapCalendarItemProps> = ({displayType}) => {
    const { item } = useContext(AccessibilityMapItemContext);
    const { visibleTimeEnd, visibleTimeStart } = useContext(TimeRangeContext);
    const { end_time, start_time, typeCode, value } = item;
    const borderWidth = 1;

    const borderColor = CalendarColors.getAccessibilityStateCodeBorderMap(typeCode, value);

    return (
        <div
            className={classNames('rct-item-content', 'calendar-item-content')}
            style={{
                borderLeft: visibleTimeStart - +start_time >= 0
                    ? undefined
                    : borderColor != null
                        ? undefined
                        : `${borderWidth}px solid ${borderColor ?? '#FFFFFF'}`,
                marginRight:
                    visibleTimeEnd - +end_time < borderWidth
                        ? undefined
                        : typeCode === TimetableTypeCodeEnum.STOCK && value !== 0
                        ? borderWidth
                        : undefined,
            }}
        >
            <AccessibilityMapContent displayType={displayType} />
        </div>
    );
};
