import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { LocationInfoRead, serverApi } from '../../../../../../../server';
import { AppDispatch, GetState } from '../../../../../../../../index';
import { showNotification } from '../../../../../../../components/notification/showNotification';
import { goBack } from 'connected-react-router';
import { getServerError } from '../../../../../../../shared/util/utils';
import { locationsApi } from '../../api/locations.api';
import { BaseThunkResponse } from '../../../../../../../store/rtqQueryApi/types';
import { CreateLocationArgs, DeleteContactFromLocationArgs, LocationState, UpdateLocationArgs } from './types';

const ACTION_TYPES = {
    CREATE_LOCATION: 'location/CREATE_LOCATION',
    UPDATE_LOCATION: 'location/UPDATE_LOCATION',
    LOAD_LOCATION: 'location/LOAD_LOCATION',
    DELETE_LOCATION: 'location/DELETE_LOCATION',
};

const initialState: LocationState = {
    loading: false,
    loadingError: undefined,
    updating: false,
    updatingError: undefined,
    entity: null,
};

const createLocationThunk = createAsyncThunk(ACTION_TYPES.CREATE_LOCATION, async (args: CreateLocationArgs, { rejectWithValue }) => {
    const { businessAccountId, locationInfoCreate } = args;
    try {
        return await serverApi.createLocation(businessAccountId, locationInfoCreate);
    } catch (err) {
        throw rejectWithValue(err);
    }
});

export const createLocation = (args: CreateLocationArgs) => (dispatch: AppDispatch) => {
    return dispatch(createLocationThunk(args))
        .unwrap()
        .then((result) => {
            dispatch(locationsApi.util?.invalidateTags(['LocationsList', 'Location']));
            return result.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const updateLocationThunk = createAsyncThunk(ACTION_TYPES.UPDATE_LOCATION, async (args: UpdateLocationArgs, { rejectWithValue }) => {
    const { businessAccountId, locationId, locationInfoUpdate } = args;

    try {
        return await serverApi.updateLocationById(businessAccountId, locationId, locationInfoUpdate);
    } catch (error: unknown) {
        throw rejectWithValue(error);
    }
});

export const updateLocation = (args: UpdateLocationArgs) => (dispatch: AppDispatch, getState: GetState) => {
    return dispatch(updateLocationThunk(args))
        .unwrap()
        .then(() => {
            showNotification('success', 'Локация успешно изменена');
            dispatch(goBack());
            dispatch(locationsApi.util?.invalidateTags(['LocationsList', 'Location']));
        })
        .catch(() => {
            showNotification('error', 'Не удалось изменить локацию');
        });
};

export const deleteContactFromLocation = (args: DeleteContactFromLocationArgs) => (dispatch, getState: GetState) => {
    const contacts = [...(args.locationInfoUpdate.contacts || [])];
    const contactIndex = contacts.findIndex((contact) => contact.contactId === args.contactId);

    contacts.splice(contactIndex, 1);

    return dispatch(
        updateLocationThunk({
            ...args,
            locationInfoUpdate: {
                ...args.locationInfoUpdate,
                contacts,
            },
        })
    ).then((result: BaseThunkResponse<LocationInfoRead>) => {
        if ('error' in result.payload) {
            showNotification('error', 'Не удалось отвязать контакт');
        } else {
            showNotification('success', 'Контакт успешно отвязан');
            dispatch(locationsApi.util?.invalidateTags(['LocationsList', 'Location']));
        }
    });
};

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        clearLocation: (state) => {
            state.entity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateLocationThunk.fulfilled, (state, action) => {
                state.updating = false;
                if (action.payload.data) state.entity = action.payload.data;
            })
            .addCase(createLocationThunk.fulfilled, (state, action) => {
                state.updating = false;
            })

            .addMatcher(locationsApi.endpoints.locationById.matchPending, (state) => {
                state.loading = true;
                state.loadingError = undefined;
            })
            .addMatcher(locationsApi.endpoints.locationById.matchFulfilled, (state, { payload }) => {
                state.loading = false;
                state.entity = payload;
            })
            .addMatcher(locationsApi.endpoints.locationById.matchRejected, (state, { payload }) => {
                state.loading = false;
                state.loadingError = getServerError(payload);
            })

            .addMatcher(isAnyOf(createLocationThunk.pending, updateLocationThunk.pending), (state) => {
                state.updating = true;
                state.updatingError = undefined;
            })
            .addMatcher(isAnyOf(createLocationThunk.rejected, updateLocationThunk.rejected), (state, action) => {
                state.updating = false;
                state.updatingError = getServerError(action.payload);
            });
    },
});

export const { clearLocation } = locationSlice.actions;
export default locationSlice.reducer;
