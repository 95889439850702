import {FAILURE, REQUEST, SUCCESS} from '../../../../../shared/reducers/action-type.util';
import {
    serverApi,
    ServerError,
    SubrentInfoCreate,
    SubrentInfoRead,
    SubrentInfoUpdate,
    SubrentTransitionCodeEnum
} from '../../../../../server';
import {push} from 'connected-react-router';
import {getServerError} from '../../../../../shared/util/utils';
import {showNotification} from '../../../../../components/notification/showNotification';
import {LocalizationEnum, localizeIntl} from '../../../../../localization';

export const ACTION_TYPES = {
    LOAD_ENTITY: 'subrent/LOAD_ENTITY',
    UPDATE_ENTITY: 'subrent/UPDATE_ENTITY',
    CHANGE_ENTITY_STATUS: 'subrent/CHANGE_ENTITY_STATUS',
    CREATE_ENTITY: 'subrent/CREATE_ENTITY',
    RESET: 'subrent/RESET',
    RESET_ERROR: 'subrent/RESET_ERROR'
};

const initialState = {
    loading: false,
    loadingError: undefined as (undefined | ServerError),
    updating: false,
    updatingError: undefined as (undefined | ServerError),
    entity: null as (SubrentInfoRead | null),
    statusWasChangedFlag: false
};

export type SubrentState = Readonly<typeof initialState>;

// Reducer

export default (state: SubrentState = initialState, action): SubrentState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: undefined,
                loading: true
            };

        case FAILURE(ACTION_TYPES.LOAD_ENTITY):
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false
            };

        case SUCCESS(ACTION_TYPES.LOAD_ENTITY):

            return {
                ...state,
                entity: action.payload.data,
                loading: false
            };

        case REQUEST(ACTION_TYPES.CREATE_ENTITY):
        case REQUEST(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: true,
                updatingError: undefined
            };

        case FAILURE(ACTION_TYPES.CREATE_ENTITY):
        case FAILURE(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false,
                updatingError: getServerError(action.payload)
            };

        case REQUEST(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: true
            };

        case FAILURE(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false
            };

        case SUCCESS(ACTION_TYPES.CREATE_ENTITY):
        case SUCCESS(ACTION_TYPES.UPDATE_ENTITY):
            return {
                ...state,
                updating: false
            };

        case SUCCESS(ACTION_TYPES.CHANGE_ENTITY_STATUS):
            return {
                ...state,
                loading: false,
                statusWasChangedFlag: true
            };

        case ACTION_TYPES.RESET:
            return {
                ...initialState
            };

        case ACTION_TYPES.RESET_ERROR:
            return {
                ...state,
                updatingError: undefined,
                loadingError: undefined
            };
        default:
            return state;
    }
};

// Actions

// Получение сущности
export const loadEntity = (businessAccountId: number, id: number) => ({
    type: ACTION_TYPES.LOAD_ENTITY,
    payload: serverApi.getSubrentById(businessAccountId, id)
});

// Создание сущности
export const createShipment = (intl, businessAccountId: number, entity: SubrentInfoCreate, cb?: (data:SubrentInfoRead)=>void) => {

    let newEntity = {...entity};
    //if (entity.acceptedPaymentsSum === undefined) {
    //    newEntity.acceptedPaymentsSum = 0;
    //}
    return ({
        type: ACTION_TYPES.CREATE_ENTITY,
        payload: serverApi.createSubrent(businessAccountId, newEntity)
    });
};

// Изменение сущности
export const updateEntity = (intl, businessAccountId: number, id: number, entity: SubrentInfoUpdate) => {
    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateSubrentById(businessAccountId, id, entity)
        }).then((result) => {
            if(result instanceof Error){
                showNotification('error', localizeIntl(intl, LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__UPDATE_FAILED)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            }else{
                // Сущность обновлена успешно
                showNotification('success', localizeIntl(intl, LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENT_UPDATED)); // TODO мб выводить норм сообщение
                 dispatch(push('/' + businessAccountId + '/subrent/shippings/' + id + '?tab=description'));
                 dispatch(loadEntity(businessAccountId, id));
            }
        });
    };
};

export const deleteContactFromShipping = (businessAccountId: number, contactId: number, entity: SubrentInfoUpdate) => {
    return dispatch => {

        const contacts = [...(entity.contactLinks || [])];
        if(contacts.length > 1){
            const contact = contacts.find((contact) => contact.contactId === contactId);
            if(contact){
                const contactIndex = contacts.indexOf(contact);
                if(contact.isMainContact){
                    if(contacts[contactIndex+1]){
                        contacts[contactIndex+1].isMainContact = true;
                    }else if(contacts[contactIndex-1]){
                        contacts[contactIndex-1].isMainContact = true;
                    }
                }
                contacts.splice(contactIndex, 1);
            }
        }

        return dispatch({
            type: ACTION_TYPES.UPDATE_ENTITY,
            payload: serverApi.updateSubrentById(businessAccountId, entity.id!, {...entity, contactLinks: contacts})
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось отвязать контакт');
            } else {
                showNotification('success', 'Контакт успешно отвязан');
                dispatch(loadEntity(businessAccountId, entity.id!));
            }
        });
    };
};

// Изменение статуса
export const setStatusAndArchive = (intl, businessAccountId: number, id: number, businessVersion: number | undefined, status?: SubrentTransitionCodeEnum, archive?: boolean) => {

    return dispatch => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ENTITY_STATUS,
            payload: serverApi.transitSubrentWorkflowBulk(businessAccountId, {
                directives: [{
                    id,
                    businessVersion: businessVersion || 0,
                    transitionCode: status,
                    archive
                }]
            })
        }).then((result) => {
            if(result instanceof Error){
                // Статус не изменен
                showNotification('error', localizeIntl(intl,LocalizationEnum.ASPECT__GLOBAL__ERROR)); // TODO Обработать ошибку от сервера, и выводить норм сообщение
            }else{
                if(archive === undefined){
                    showNotification('success', localizeIntl(intl,LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__SHIPMENT_STATE_UPDATED));
                }else{
                    showNotification('success', localizeIntl(intl, archive ? LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__ARCHIVED : LocalizationEnum.PAGE__SHIPPINGS__POPUP_NOTIFICATIONS__UNARCHIVED));
                }
                dispatch(loadEntity(businessAccountId, id));
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET
});

export const resetError = () => ({
    type: ACTION_TYPES.RESET_ERROR
});
