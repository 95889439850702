import React, {CSSProperties, ReactNode, useRef, useState} from 'react';
import {AttachmentParentEntityTypeCodeEnum, ContactLinkObjRead} from '../../server';
import { Icon } from 'antd';
import {IconEllipsisHSolid, IconNoImage, IconUnlinkSolid} from '../icons';
import { SimpleTable, SimpleTableColumn } from '../v2/simpleTable/SimpleTable';
import { CollapsibleBlock } from '../v2/collapsibleBlock/CollapsibleBlock';
import { ActionsPopoverV2 } from '../v2/actionsPopover/ActionsPopoverV2';
import { LocalizationEnum, localize } from '../../localization';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ContactsBlockSettings, updateContactsBlockSettings } from '../../shared/reducers/userSettings/userSettings.reducer';
import {getImagePath, printUserName} from '../../shared/util/utils';
import { Avatar } from '../avatar/avatar';
import { Spin } from '../index';
import { showConfirm2 } from '../confirm/showConfirm';
import { useIntl } from 'react-intl';
import './contactsBlock.less';
import { ContactDrawer } from '../../modules/main/renters/tabs/contacts/drawer/components/ContactDrawer/ContactDrawer';

import { useContactActionsFromDrawer } from '../../modules/main/renters/tabs/contacts/hooks/useContactActionsFromDrawer';

interface ContactsBlockProps {
    data: ContactLinkObjRead[];
    style?: CSSProperties;
    parentEntityId: number;
    parentEntityTypeCode: ContactsBlockParentEntityTypeCode;
    deleteContact: (contactId: number) => Promise<unknown>;
    preventDeleteRemainingContact?: boolean;
    preventDeleteContact?: boolean;
    contactsFromEntityLabel?: string;
}

export type ContactsBlockParentEntityTypeCode = AttachmentParentEntityTypeCodeEnum.LOCATION | AttachmentParentEntityTypeCodeEnum.VEHICLE | AttachmentParentEntityTypeCodeEnum.PROJECT | AttachmentParentEntityTypeCodeEnum.RENTER | AttachmentParentEntityTypeCodeEnum.SUBRENT;

export const ContactsBlock = ({ data, ...props }: ContactsBlockProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const settings = useAppSelector(
        (state) => state.userSettings.contactsBlockSettings[props.parentEntityTypeCode] || { collapsed: false }
    );
    const setSettings = (_settings: ContactsBlockSettings) =>
        dispatch(updateContactsBlockSettings({ [props.parentEntityTypeCode]: { ...settings, ..._settings } }));
    const [fetching, setFetching] = useState(false);
    const divRef = useRef<HTMLDivElement | null>(null);

    let columns: SimpleTableColumn<ContactLinkObjRead>[] = [
        {
            id: 'image',
            width: 26,
            minWidth: 26,
            maxWidth: 26,
            style: { lineHeight: 1, minHeight: 40 },
            render: (value, row) => {
                return value ? (
                    <Avatar size={26} style={{ backgroundColor: '#ffffff', verticalAlign: 'middle' }} src={getImagePath(row.image, '40')} />
                ) : (
                    <Avatar size={26} style={{ backgroundColor: '#ffffff', verticalAlign: 'middle' }}>
                        <span style={{ fontSize: 18 }}>
                            <Icon component={IconNoImage} style={{ color: '#DDDDDD' }} />
                        </span>
                    </Avatar>
                );
            },
        },
        {
            id: 'fullName',
            width: 200,
            style: { alignItems: 'flex-start', paddingLeft: 18 },
            className: 'rr-attachments-block_attachment-name-link',
            render: (value, row) => {
                const name = printUserName(row.fullName, true);
                return (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                            <a onClick={() => {
                                setContactId(row.contactId);
                            }} title={name}>{
                                name
                            }</a>
                        </div>
                        {row.isMainContact && <span style={{fontSize: 11, color: '#AAAAAA'}}>Основной</span>}
                    </div>
                );
            },
        },
        {
            id: 'position',
            width: 150,
            style: {alignItems: 'flex-start', paddingRight: 10},
            render: (value, row) => {
                return value;
            },
        },
        {
            id: 'contactId',
            width: 32,
            minWidth: 32,
            maxWidth: 32,
            style: { lineHeight: 1 },
            render: (value, row) => {
                return (props.preventDeleteContact ||  (props.preventDeleteRemainingContact && data.length <= 1)) ? null : (
                    <ActionsPopoverV2
                        getPopupContainer={(triggerNode) => divRef.current || (triggerNode.parentNode as HTMLElement) || triggerNode}
                        align={{ offset: [0, -6] }}
                        items={[
                            {
                                title: 'Отвязать контакт',
                                icon: <Icon component={IconUnlinkSolid} style={{color: '#e83e8c'}} />,
                                onClick: async () => {
                                    const yes = await showConfirm2({ intl, title: 'Отвязать контакт?' });
                                    if (yes) {
                                        setFetching(true);
                                        try {
                                            await props.deleteContact(row.contactId);
                                        } catch (error) {}
                                        setFetching(false);
                                    }
                                },
                            },
                        ]}
                    >
                        <Icon component={IconEllipsisHSolid} style={{ fontSize: 36, color: '#525A95', userSelect: 'none' }} />
                    </ActionsPopoverV2>
                );
            },
        },
    ];

    let collapsed = settings.collapsed;
    if (data.length === 0) collapsed = true;

    const [contactId, setContactId] = useState<number | undefined>();
    const {
        edit: editAction,
        delete: deleteAction,
        archive: archiveAction,
        isLoading: isEntityActionsLoading,
    } = useContactActionsFromDrawer();

    const contactsFromEntityLabel:ReactNode = props.contactsFromEntityLabel ? (
        <span style={{color: '#AAAAAA', marginLeft: 12}}>({props.contactsFromEntityLabel})</span>
    ) : null;

    return (
        <div ref={divRef}>
            <CollapsibleBlock
                collapsed={collapsed}
                onCollapse={(collapsed) => {
                    setSettings({ collapsed });
                }}
                className={'rr-contacts-block'}
                title={
                    data.length === 0 ? (
                        <>Контактов нет{contactsFromEntityLabel}</>
                    ) : (
                        <>
                            {data.length} {localize(LocalizationEnum.ASPECT__PLURAL__CONTACT, undefined, { value: data.length })}{contactsFromEntityLabel}
                        </>
                    )
                }
                icon={<SystemIcon type={SystemIconType.contact} />}
                disabled={data.length === 0}
                style={props.style}
                extraContent={<div style={{ height: 38 }} />}
            >
                <Spin spinning={fetching}>
                    <SimpleTable columns={columns} data={data} />
                </Spin>
            </CollapsibleBlock>

            {contactId && (
                <ContactDrawer
                    contactId={contactId}
                    key={contactId}
                    onClose={() => {
                        setContactId(undefined);
                    }}
                    refetchList={() => {}}
                    editContact={editAction}
                    deleteContact={deleteAction}
                    archiveContact={archiveAction}
                    isEntityActionsLoading={isEntityActionsLoading}
                />
            )}
        </div>
    );
};
